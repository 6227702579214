
  import AlertBox from '@/components/elements/AlertBox';
  import ProfileIncomplete from '@/mixins/ProfileIncomplete';

  export default {
    name: 'ProfileIncompleteAlert',
    components: {
      AlertBox,
    },
    mixins: [ProfileIncomplete],
    methods: {
      redirectToMissingData() {
        this.$router.push(this.url);
      },
    },
  };
